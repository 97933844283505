<template>
  <nav
    class="right_bg close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative z-10"
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
  >
    <div class="">
      <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
        <div class="righ_side_head_">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="4" viewBox="0 0 12 4" fill="none">
            <path d="M11.3333 0H0.666626V1.33333H11.3333V0ZM0.666626 4H11.3333V2.66667H0.666626V4Z" fill="#777777"></path>
          </svg>
          <p class="sidebar-heading">Rosebud</p>
        </div>
        <div>
          <svg v-on:click="closeNavigation" class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
            <path id="close_2_" data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)" fill="#b4b5ba" />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_one"
        class="flex justify-between pt-4 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/genaral_settings.png" alt="" />
            General Settings
          </p>
        </div>
        <div class="d-flex self-center lables_with_ico">
          <p v-if="!isExpandFeatureOne"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div v-bind:style="{ display: isExpandFeatureOne ? '' : 'none' }" class="expand-right-sidebar-feature-one">
        <div class="">
          <div class="custom-radio-wrap mb-4">
            <div class="flex flex-col mt-3">
              <p class="label_heading">Log Type</p>
              <select
                id="well"
                class=" fields border-0 block left-0 font-color leading-none"
                :value="log_type"
                @change="getLogeType"
              >
                <option value="trajectory">Trajectory</option>
                <option value="time">Time</option>
              </select>
            </div>

            <div class="flex flex-col mt-3">
              <p class="label_heading">Unit</p>
              <input
                disabled
                type="text"
                class="fields border-0 block font-color"
                value="DEGA"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-on:click="toggle_height_handler_two"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/note.png" alt="" />
            Appearance
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureTwo"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div v-bind:style="{ display: isExpandFeatureTwo ? '' : 'none' }" class="expand-right-sidebar-feature-one">
        <div class="pb-5">
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Color</p>
            <input type="color" min="0" max="13" id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color" @change="changeTextColor($event)"
              :value="textColor" />
          </div>

          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Background Color</p>
            <input type="color" min="0" max="13" id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color" @change="changeBackgroundColor($event)"
              :value="backgroundColor" />
          </div>
        </div>
      </div>
      <!-- <div v-on:click="toggle_height_handler_three" class="flex  justify-between pt-4 pl-5 pb-4"
        :class="darkDark ? ' bg-right_' : 'bg-right_light'">
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/shared_vision.png" alt="" />
            Visibility
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureThree"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>

        </div>
      </div> -->

      <!-- <div v-bind:style="{ display: isExpandFeatureThree ? '' : 'none' }" class="expand-right-sidebar-feature-one">
        <div class="pb-3">
          <div class="custom-radio-wrap mb-4">
            <div class="flex items-center justify-between mt-3">
              <p class="label_heading">Grid</p>
              <input class="ml-auto leading-tight" type="checkbox" id="men1"
                @change="changeGridVisibilityHandler($event)" :checked="showGrid" />
            </div>

            <div class="flex items-center justify-between mt-3">
              <p class="label_heading">Radar</p>
              <input class="ml-auto leading-tight" type="checkbox" id="men2"
                @change="changeRadarVisibilityHandler($event)" :checked="isRadarVisible" />

            </div>
          </div>
        </div>
      </div> -->
      <div
        v-on:click="toggle_height_handler_four"
        class="flex justify-between pt-4 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/menu_4.png" alt="" />
            Data Points
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureFour"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div v-bind:style="{ display: isExpandFeatureFour ? '' : 'none' }" class="expand-right-sidebar-feature-one">
        <div class="">
          <div class="custom-radio-wrap mb-4">
            <div class="flex flex-col mt-3">
              <p class="label_heading">Number of Points</p>
              <input
                type="number"
                class="fields border-0 block font-color"
                @change="changeDataPointsHandler($event)"
                :value="numberOfDataPoints"
              />
            </div>

            <!-- <div class="flex items-center justify-between mt-3">
                <p class="label_heading">Start Range</p>
                <input
                  type="number"
                  min="-30"
                  max="90"
                  class="
                    focus:ring-indigo-500
                    label_heading
                    h-9
                    border-0
                    input-bg
                    block
                    w-5/12
                    rounded-md
                    font-color
                  "
                  @input="changeStartRangeHandler($event)"
                />
              </div>
              <div class="flex items-center justify-between mt-3">
                <p class="label_heading">End Range</p>
                <input
                  type="number"
                  min="90"
                  max="360"
                  class="
                    focus:ring-indigo-500
                    label_heading
                    h-9
                    border-0
                    input-bg
                    block
                    w-5/12
                    rounded-md
                    font-color
                  "
                  @input="changeEndRangeHandler($event)"
                />
              </div>-->

            <!-- <div class="flex items-center justify-between mt-3">
                <p class="label_heading">Radar Range End</p>
                <input
                  type="number"
                  min="90"
                  max="360"
                  class="
                    focus:ring-indigo-500
                    label_heading
                    h-9
                    border-0
                    input-bg
                    block
                    w-5/12
                    rounded-md
                    font-color
                  "
                  @input="changeRadarEndHandler($event)"
                />
              </div>-->
          </div>
        </div>
      </div>

      <UpDownButtons :isExpandFeatureSix="isExpandFeatureSix" :toggle_height_handler_six="toggle_height_handler_six"
        :id="id" :displayId="properties.displayId" :widgetId="properties.widgetId" :closePropertyFile="closeNavigation" />
    </div>
  </nav>
</template>


<script>
import UpDownButtons from "../UpDownButtons/UpDownButtons";
import api from "../../api/services";

export default {
  name: "Maindash",
  components: {
    UpDownButtons,
  },
  props: {
    widgetId: String,
    displayId: String,
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: "",
    properties: {},
    closeNavigation: Function,
  },
  data() {
    return {
      idx: -1,
      isExpandFeatureOne: true,
      isExpandFeatureTwo: false,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureFive: false,
      isExpandFeatureSix: false,
    };
  },

  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
  },

  computed: {
    log_type() {
      return this.$store.state.rect.rects[this.idx].log_type || "trajectory";
    },
    textColor() {
      return this.$store.state.rect.rects[this.idx].textColor||
        (this.darkDark !== 'white' ? "#ffffff" : "#000000")
    },
    backgroundColor() {
      return this.$store.state.rect.rects[this.idx].backgroundColor ||
        getComputedStyle(document.documentElement).getPropertyValue("--widgetBg")
    },
    showGrid() {
      return this.$store.state.rect.rects[this.idx].showGrid ||
        (this.darkDark !== 'white' ? "#ffffff" : "#000000")
    },
    radarStart() {
      return this.$store.state.rect.rects[this.idx].radarStart ||
        (this.darkDark !== 'white' ? "#ffffff" : "#000000")
    },
    numberOfDataPoints() {
      if (this.$store.state.rect.rects[this.idx].numberOfDataPoints == undefined)
        return 7
      else
        return this.$store.state.rect.rects[this.idx].numberOfDataPoints
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },

  methods: {
    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
    // changeTitleHandler(e) {
    //   this.$store.dispatch("rect/changeTitle", {
    //     displayId: this.displayId,
    //     widgetId: this.widgetId,
    //     value: e.target.value ? parseFloat(e.target.value) : 0
    //   });
    // },
    changeDataPointsHandler(e) {
      if(e.target.value > 7){
        e.target.value = 7;
        this.$toast.clear();
        this.$toast.error(`Data points should be from 1 to 7`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
      else if(e.target.value < 1){
        e.target.value = 1;
        this.$toast.clear();
        this.$toast.error(`Data points should be from 1 to 7`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
      this.$store.dispatch("rect/diectionalChangeDataPointsHandler", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value,
      });
    },
    async getLogeType(e) {
      // let response;
      // if (e.target.value == "time") {
      //   response = await api.WellServices.getLogType({
      //     logType: e.target.value,
      //   });
      // } else {
      //   let dtls = this.$store.state.disp.displays[this.displayId];
      //   response = await api.WellServices.getDirectionForTrajectory({
      //     well_id: this.wellId,
      //     wellbore_name: this.wellboreId
      //   });
      // }

      this.$store.dispatch("rect/directionalChangeLogType", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value,
      });
    },
    changeTextColor(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeTextColor", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: e.target.value,
        });
      }, 300);
    },
    changeStartRangeHandler(e) {
      this.$store.dispatch("rect/directionalStartRange", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value ? parseFloat(e.target.value) : 0,
      });
    },
    changeEndRangeHandler(e) {
      this.$store.dispatch("rect/directionalEndRange", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value ? parseFloat(e.target.value) : 0,
      });
    },
    changeRadarStartHandler(e) {
      this.$store.dispatch("rect/directionalRadar", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value ? parseFloat(e.target.value) : 0,
      });
    },

    changeGridVisibilityHandler(e) {
      this.$store.dispatch("rect/toggleGridVisibility", {
        displayId: this.displayId,
        widgetId: this.widgetId,
      });
    },
    changeRadarVisibilityHandler(e) {
      this.$store.dispatch("rect/changeRadarVisibilityHandler", {
        displayId: this.displayId,
        widgetId: this.widgetId,
      });
    },
    // changeRadarEndHandler(e) {
    //   this.$store.dispatch("rect/directionalEndRadar", {
    //     displayId: this.displayId,
    //     widgetId: this.widgetId,
    //     value: e.target.value ? parseFloat(e.target.value) : 0
    //   });
    // },
    changeBackgroundColor(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeBackgroundColor", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: e.target.value,
        });
      }, 300);
    },

    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_five() {
      if (this.isExpandFeatureFive == true) {
        this.isExpandFeatureFive = false;
      } else {
        this.isExpandFeatureFive = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css">
/* .right_bg {
  background: #1b1a1f;
}
.bg_gray {
  width: 12%;
}
.bg-right_ {
  background: #28282f;
  margin: 5px 5px;
  padding: 5px;
  border-radius: 5px;
}
.sidebar-heading {
  font-size: 9px;
  padding: 2px;
}
.sidebar-label {
  font-size: 10px;
  font-family: 'Poppins', sans-serif;
}
.label_heading {
  color: white !important;
  font-size: 8px;
  letter-spacing: 0.5px;
}
.custom-radio-wrap form .form-group-radio .label-text {
  color: white;
}

.font-color {
  color: white;
  font-size: 10px;
  padding: 0px 0px;
  height: 20px;
  width: 53%;
  text-align: center;
} */
</style>